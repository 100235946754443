// ** React Imports
import { useState, useEffect } from "react";

// ** MUI Imports
import TabContext from "@mui/lab/TabContext";
import MuiTab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Box from "@mui/material/Box";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Tab Modules
import Session from './TabModules/Session'

// 
// import GeneralHistory from "src/modules/GeneralHistory";


// Tabs Modules
// Security
// import ChangePassword from "./Tabs/Security/ChangePassword";
// // Contact
// import Mailing from "./Tabs/Contact/Mailing";
// // Cards
// import Cards from "./Tabs/Cards/Cards";
// History


// ** Styled Tab component
const Tab = styled(MuiTab)(({ theme }) => ({
  minHeight: 48,
  flexDirection: "row",
  "& svg": {
    marginBottom: "0 !important",
    marginRight: theme.spacing(1),
  },
}));

const OperatorTabs = ({ data }) => {
  const [activeTab, setActiveTab] = useState("security");

  const handleChange = (event, value) => {
    setActiveTab(value);
  };

  return (
    <TabContext value={activeTab}>
      <TabList
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
        aria-label="forced scroll tabs example"
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
         <Tab
          value="security"
          label="Безопасность"
          icon={<Icon icon="material-symbols:lock-outline" />}
        />
         <Tab
          value="history"
          label="История"
          icon={<Icon icon="material-symbols:history-edu-rounded" />}
        />
      
      </TabList>
      <TabPanel value="security">
        <Session data={data}/>
        {/* <ChangePassword id={client._id} /> */}
      </TabPanel>
      <TabPanel value="history">
        {/* <GeneralHistory mode='client' id={client._id} /> */}
      </TabPanel>
    </TabContext>
  );
};

export default OperatorTabs;
