// States
import { useState } from "react";

// Mui
import {
  Card,
  Grid,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction,
  CardHeader,
  Fab,
} from "@mui/material";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Modules
import RoleEdit from "./RoleEdit";

function Roles() {
  let [openEdit, setOpenEdit] = useState(false);

  return (
    <Card>
      <RoleEdit
        open={openEdit}
        closeHandler={() => {
          setOpenEdit(false);
        }}
      />
      <CardHeader
        title={"Роли"}
        {...{
          action: (
            <Fab
              color="primary"
              variant="extended"
              size="medium"
              sx={{ "& svg": { mr: 1 } }}
              onClick={() => setOpenEdit(true)}
            >
              <Icon icon="mdi:plus" />
              Добавить
            </Fab>
          ),
        }}
      />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText sx={{}} primary="Оператор" />
            <ListItemSecondaryAction>
              <IconButton edge="end">
                <Icon icon="material-symbols:edit" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemButton>
        </ListItem>
      </List>
    </Card>
  );
}

export default Roles;
