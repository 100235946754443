import React, { useState, useEffect, useCallback } from "react";

// ** MUI Imports
import Grid from "@mui/material/Grid";

// **  Components Imports
import Details from "./Details";
import Tabs from "./Tabs";

// ** Router
import { useParams } from "react-router-dom";

// Service
import AdminService from "src/service/Auth";

const Operator = () => {
  // Data
  let [data, setData] = useState(null);

  // ** Hooks
  const params = useParams();

  // API
  useEffect(() => {
    AdminService.getAdminOne(params.id).then((r) => setData(r.data));
  }, []);

  // Handlers

  if (!data) return null;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={5} lg={4}>
        <Details data={data}/>
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <Tabs data={data}/>
      </Grid>
    </Grid>
  );
};

export default Operator;
