import { useState, useEffect } from "react";

// Mui
import { Card, Grid, Box, Button } from "@mui/material";

// Ui
import PageWrap from "src/components/PageWrap";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Components
import OperatorCard from "./OperatorCard";
import AddOperator from "./AddOperator";

// Modules
import Roles from "./Roles/Roles";

// Services
import AdminService from "src/service/Auth";

function Operators() {
  // States
  let [operators, setOperators] = useState([]);
  let [showAdd, setShowAdd] = useState(false);

  // Handlers
  const toggleShowAdd = () => setShowAdd(!showAdd);

  // Hooks
  useEffect(() => {
    AdminService.getAdminList().then((r) => setOperators(r.data));
  }, []);

  // Handlers
  const addOperatorHandler = (item) => setOperators((list) => [item, ...list]);

  return (
    <PageWrap title="Операторы и доступ">
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Roles />
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <Box
              sx={{ px: 5, py: 3, display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                onClick={toggleShowAdd}
                variant="contained"
                endIcon={<Icon icon="mingcute:user-add-line" />}
              >
                Добавить
              </Button>
            </Box>
          </Card>
          <Grid sx={{ mt: 3 }} container spacing={4}>
            {operators.map((o) => {
              return (
                <Grid key={o._id} item xs={12} sm={6} md={4}>
                  <OperatorCard data={o} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      {/* <AddOperator addItem={addOperatorHandler} open={showAdd} toggle={toggleShowAdd} />
       */}
    </PageWrap>
  );
}

export default Operators;
