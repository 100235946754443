// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TableContainer from "@mui/material/TableContainer";
import FormControlLabel from "@mui/material/FormControlLabel";

// ** Icon Imports
import Icon from "src/@core/components/icon";

const rolesArr = [
  "User Management",
  "Content Management",
  "Disputes Management",
  "Database Management",
  "Financial Management",
  "Reporting",
  "API Control",
  "Repository Management",
  "Payroll",
];

const RolesCards = (props) => {
  let { data, open, closeHandler } = props;

  // ** States

  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [isIndeterminateCheckbox, setIsIndeterminateCheckbox] = useState(false);

  // Calc
  let isNew = !data;

  const handleClose = () => {
    closeHandler();
    setSelectedCheckbox([]);
    setIsIndeterminateCheckbox(false);
  };

  const togglePermission = (id) => {
    const arr = selectedCheckbox;
    if (selectedCheckbox.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedCheckbox([...arr]);
    } else {
      arr.push(id);
      setSelectedCheckbox([...arr]);
    }
  };

  const handleSelectAllCheckbox = () => {
    if (isIndeterminateCheckbox) {
      setSelectedCheckbox([]);
    } else {
      rolesArr.forEach((row) => {
        const id = row.toLowerCase().split(" ").join("-");
        togglePermission(`${id}-read`);
        togglePermission(`${id}-write`);
        togglePermission(`${id}-create`);
      });
    }
  };
  useEffect(() => {
    if (
      selectedCheckbox.length > 0 &&
      selectedCheckbox.length < rolesArr.length * 3
    ) {
      setIsIndeterminateCheckbox(true);
    } else {
      setIsIndeterminateCheckbox(false);
    }
  }, [selectedCheckbox]);

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="body"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <Typography variant="h5" component="span">
            {`${isNew ? "Добавить" : "Изменить"} роль`}
          </Typography>
          <Typography variant="body2">Установите доступ для роли</Typography>
        </DialogTitle>
        <DialogContent sx={{ p: { xs: 6, sm: 12 } }}>
          <Box sx={{ my: 4 }}>
            <FormControl fullWidth>
              <TextField label="Название роли" placeholder="Введите название роли" />
            </FormControl>
          </Box>
          <Typography variant="h6">Доступно для роли</Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ pl: "0 !important" }}>
                    <Box
                      sx={{
                        display: "flex",
                        fontSize: "0.875rem",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                        textTransform: "capitalize",
                        "& svg": { ml: 1, cursor: "pointer" },
                      }}
                    >
                      Доступ Администратора
                      <Tooltip
                        placement="top"
                        title="Полный доступ системы"
                      >
                        <Box sx={{ display: "flex" }}>
                          <Icon
                            icon="mdi:information-outline"
                            fontSize="1rem"
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <FormControlLabel
                      label="Выбрать все"
                      sx={{
                        "& .MuiTypography-root": {
                          textTransform: "capitalize",
                        },
                      }}
                      control={
                        <Checkbox
                          size="small"
                          onChange={handleSelectAllCheckbox}
                          indeterminate={isIndeterminateCheckbox}
                          checked={
                            selectedCheckbox.length === rolesArr.length * 3
                          }
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rolesArr.map((i, index) => {
                  const id = i.toLowerCase().split(" ").join("-");

                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "& .MuiTableCell-root:first-of-type": {
                          pl: "0 !important",
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          fontWeight: 600,
                          whiteSpace: "nowrap",
                          color: (theme) =>
                            `${theme.palette.text.primary} !important`,
                        }}
                      >
                        {i}
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          label="Чтение"
                          control={
                            <Checkbox
                              size="small"
                              id={`${id}-read`}
                              onChange={() => togglePermission(`${id}-read`)}
                              checked={selectedCheckbox.includes(`${id}-read`)}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          label="Изменение"
                          control={
                            <Checkbox
                              size="small"
                              id={`${id}-write`}
                              onChange={() => togglePermission(`${id}-write`)}
                              checked={selectedCheckbox.includes(`${id}-write`)}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          label="Создание"
                          control={
                            <Checkbox
                              size="small"
                              id={`${id}-create`}
                              onChange={() => togglePermission(`${id}-create`)}
                              checked={selectedCheckbox.includes(
                                `${id}-create`
                              )}
                            />
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions
          sx={{ pt: 0, display: "flex", justifyContent: "center" }}
        >
          <Box className="demo-space-x">
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={handleClose}
            >
              Сохранить
            </Button>
            <Button
              size="large"
              color="secondary"
              variant="outlined"
              onClick={handleClose}
            >
              Отменить
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RolesCards;
